import React from 'react'

import Header from '~components/Header'
import Footer from '~components/Footer'

import LatestHero from '~components/pages/latest/Hero'
import LatestFeed from '~components/pages/latest/Feed'

const LatestPageWrap = ({ data, isPreview }) => {
  return (
    <>
      <Header isPreview={isPreview} />
      <LatestHero data={data.hero} isPreview={isPreview} />
      <LatestFeed data={data.feed} isPreview={isPreview} />
      <Footer isPreview={isPreview} />
    </>
  )
}

export default LatestPageWrap
