import React from 'react'

import Layout from '~global/Layout'
import LatestPageWrap from '~pageWraps/LatestPageWrap'

import data from '../content/latest.yaml'

const Latest = () => {
  return (
    <Layout>
      <LatestPageWrap data={data} />
    </Layout>
  )
}

export default Latest
