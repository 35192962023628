import React from 'react'

import './index.scss'

const LatestHero = ({ data }) => {
  return (
    <section className="latest-hero">
      <div className="latest-hero__container">
        <h1 className="latest-hero__title">{data.title}</h1>
      </div>
    </section>
  )
}

export default LatestHero
