import React from 'react'

import Card from '~components/Card'

import './index.scss'
import useMediaQuery from '~hooks/useMediaQuery'
import useIntersectionObserver from '~components/global/IntersectionObserver'

const CardWrapper = ({ item, isPreview }) => {
  //@TODO: move IO code into Card component
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-15%",
    threshold: 0
  })

  const cardData = (card) => {
    const caption = card.caption.length > 50 ? card.caption.slice(0, 47).concat('...') : card.caption
    return (
      {
        ruler: {
          rightText: {
            tag: 'button',
            text: 'More',
            url: card.url,
            color: 'white'
          },
          leftText: {
            color: 'grey',
            text: card.tag,
            type: 'label',
          }
        },
        description: caption,
        image_url: card.image
      }
    )
  }

  return (
    <a className="latest-feed__card--wrapper" href={item.url} target="_blank" rel="noopener noreferrer" ref={componentRef}>
      <Card data={cardData(item)} isPreview={isPreview} />
    </a>
  )
}

const LatestFeed = ({ data, isPreview }) => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  const createColumn = (columnData, columnIndex) => {
    return (
      <div className="latest-feed__grid__column" key={`column-${columnIndex}`}>
        {
          columnData.map((item, index) =>
            <CardWrapper item={item} key={`column-${columnIndex}-card-${index}`} isPreview={isPreview} />
          )
        }
      </div>
    )
  }

  let columns = isMobile ? Array.from(Array(2), () => []) : Array.from(Array(3), () => [])
  let counter = 0
  let resetIndex  = isMobile ? 2 : 5
  data.forEach((item, index) => {
    const column = !isMobile && counter >= 3 ? counter - 2 : counter
    columns[column].push(item)
    counter += 1
    counter = counter % resetIndex
  })

  return (
    <section className="latest-feed">
      <div className="latest-feed__container">
        <div className="latest-feed__grid">
          {columns.map((column, index) =>
            createColumn(column, index)
          )}
        </div>
      </div>
    </section>
  )
}

export default LatestFeed
